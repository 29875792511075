import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImageDataByFilename } from '../../utils/getImageDataByFilename'

type PartnershipArrayItem = {
  title: string
  image: unknown
  link: string
}

const partnershipsArray: PartnershipArrayItem[] = [
  {
    title: 'Volkswagen',
    image: 'VolkswagenSVG.png',
    link: '',
  },
  {
    title: 'AWS',
    image: 'aws.png',
    link: '',
  },
  {
    title: 'imec',
    image: 'imec.png',
    link: '',
  },
  {
    title: 'VTT',
    image: 'vtt-logo.png',
    link: '',
  },
  {
    title: 'BMW',
    image: 'BMWGroup_PNG.png',
    link: '',
  },
  {
    title: 'Nvidia',
    image: 'Nvidia.png',
    link: '',
  },
  {
    title: 'GlobalFoundries',
    image: 'globalfoundries.png',
    link: '',
  },
  {
    title: 'NIST',
    image: 'NIST_logo.png',
    link: '',
  },
  {
    title: 'ligentec',
    image: 'ligentec.png',
    link: '',
  },
  {
    title: 'Google',
    image: 'google.png',
    link: '',
  },
  {
    title: 'IBM',
    image: 'ibm.png',
    link: '',
  },
  {
    title: 'Zapata Computing',
    image: 'zapata.png',
    link: '',
  },
  {
    title: 'Scotiabank',
    image: 'scotiabank.png',
    link: '',
  },
  {
    title: 'Microsoft',
    image: 'microsoft.png',
    link: '',
  },
  {
    title: 'CMC Microsystems',
    image: 'cmc.png',
    link: '',
  },
  {
    title: 'Rigetti',
    image: 'rigetti.png',
    link: '',
  },
  {
    title: 'Quantinuum',
    image: 'Quantinuum.png',
    link: '',
  },
  {
    title: 'Strangeworks',
    image: 'strangeworks.png',
    link: '',
  },
  {
    title: 'Creative Destruction Lab',
    image: 'creative-destruction-lab.png',
    link: '',
  },
  {
    title: 'AQT',
    image: 'aqt.png',
    link: '',
  },
  {
    title: 'Agnostiq',
    image: 'agnostiq.png',
    link: '',
  },
  {
    title: 'Multiverse',
    image: 'multiverse.png',
    link: '',
  },
  {
    title: 'CERN',
    image: 'cern.png',
    link: '',
  },
  {
    title: 'DARPA',
    image: 'darpa.png',
    link: '',
  },
  {
    title: 'Covestro',
    image: 'covestro.png',
    link: '',
  },
  {
    title: 'Stanford',
    image: 'stanford.png',
    link: '',
  },
  {
    title: 'Bank of Canada',
    image: 'bank-of-canada.png',
    link: '',
  },
  {
    title: 'IonQ',
    image: 'ionq.png',
    link: '',
  },
  {
    title: 'QunaSys',
    image: 'qunasys.png',
    link: '',
  },
  {
    title: 'Center for Quantum Networks',
    image: 'cqn.png',
    link: '',
  },
  {
    title: 'Harvard',
    image: 'harvard.png',
    link: '',
  },
  {
    title: 'MIT',
    image: 'mit-logo.png',
    link: '',
  },
  {
    title: 'NRC',
    image: 'nrc-logo',
    link: '',
  },
  {
    title: 'MILA',
    image: 'mila.png',
    link: '',
  },
  {
    title: 'MaRS',
    image: 'mars-logo-3.png',
    link: 'https://www.marsdd.com/',
  },
  {
    title: 'UBC',
    image: 'uBc.png',
    link: '',
  },
  {
    title: 'University of Victoria',
    image: 'uvic.png',
    link: '',
  },
  {
    title: 'Simon Fraser',
    image: 'sfu-text.png',
    link: '',
  },
  {
    title: 'Quantum Algorithm Institute',
    image: 'qai-logo.png',
    link: '',
  },
  {
    title: 'Innovative Solutions Canada',
    image: 'isc.png',
    link: 'https://www.ic.gc.ca/eic/site/101.nsf/eng/home',
  },
  {
    title: 'University of Toronto',
    image: 'uoft-logo.png',
    link: '',
  },
  {
    title: 'Institut Quantique',
    image: 'institut-quantique.png',
    link: '',
  },
  {
    title: 'Institute for Quantum Computing',
    image: 'iqc.png',
    link: '',
  },
  {
    title: 'Università di Pavia',
    image: 'universita-di-pavia.png',
    link: 'https://portale.unipv.it/it',
  },
  {
    title: 'University of Calgary',
    image: 'uCalgary.png',
    link: '',
  },
  {
    title: 'Univerity of Ottawa',
    image: 'u-ottawa-logo.png',
    link: '',
  },
  {
    title: 'Menten AI',
    image: 'menten-ai-logo.png',
    link: '',
  },
  {
    title: 'Toronto Metropolitan University',
    image: 'tmu-white.png',
    link: '',
  },
]

// Images that are a little too large and need to be shrunk down
const largeLogos: string[] = [
  'NIST',
  'IBM',
  'CMC Microsystems',
  'DARPA',
  'Google',
  'Rigetti',
  'Innovative Solutions Canada',
  'MILA',
  'IonQ',
]

// Images that are overly large and need to be shrunk down
const extraLargeLogos: string[] = [
  'Strangeworks',
  'CERN',
  'Covestro',
  'Stanford',
  'Center for Quantum Networks',
  'MaRS',
  'UBC',
  'AWS',
  'Quantum Algorithm Institute',
  'VTT',
]

const generateLogoImages = (item: PartnershipArrayItem) => {
  let logoWidth = '140px'
  if (largeLogos.includes(item.title)) {
    logoWidth = '100px'
  }
  if (extraLargeLogos.includes(item.title)) {
    logoWidth = '50px'
  }
  return (
    <div style={{ width: logoWidth, height: '100%' }}>
      <GatsbyImage
        image={getImageDataByFilename(String(item.image))}
        alt={item.title}
        className="white-image-filter max-w-full max-h-full object-contain"
      />
    </div>
  )
}

const Partnerships = () => {
  return (
    <div className="text-white mt-15">
      <div className="flex-wrap justify-center hidden -m-6 text-white partnerships-secion md:flex">
        {partnershipsArray.map((item, index) => {
          return (
            <div key={index} className="p-6">
              {generateLogoImages(item)}
            </div>
          )
        })}
      </div>
      <div className="flex flex-wrap justify-center -m-6 text-white partnerships-secion md:hidden">
        {partnershipsArray.slice(0, 6).map((item, index) => {
          return (
            <div key={index} className="p-6">
              {generateLogoImages(item)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Partnerships
