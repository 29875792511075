/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import Layout from 'components/Layout'
import Modal from 'components/Modal'
import Slider from 'components/slider'
import GetStartedParallaxSection from 'components/homepage/GetStartedParallaxSection'
import Partnerships from 'components/homepage/Partnerships'
import demosArray from 'data/demos/demosArray'

import ImageVideoPlayIconSvg from 'images/videoPlayIconSvg'

import VideoHeroLocal from 'components/VideoHeroLocal/VideoHeroLocal'
import heroVideo from 'videos/x-series/qsup_trim.mp4'
import heroVideoThumbnail from 'videos/thumbnails/qsup_thumbnail.jpeg'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImageDataByFilename } from '../../utils/getImageDataByFilename'

const cloudSteps = [
  {
    step: 'Build',
    image: 'build_img.png',
    number: 1,
    details: 'Software services for Strawberry Fields & PennyLane',
  },
  {
    step: 'Experiment',
    image: 'cloud_experiment.png',
    number: 2,
    details: 'Leverage high performance simulators',
  },
  {
    step: 'Execute',
    image: 'deploy_img.png',
    number: 3,
    details: 'Exclusive access to photonic quantum computers',
  },
  {
    step: 'Manage',
    image: 'manage_img.png',
    number: 4,
    details: 'Easy to use interface and workflow management',
  },
]

const Homepage = () => {
  const [viewportWidth, setViewportWidth] = useState(0)

  /** Keep track of the viewport/window width */
  useEffect(() => {
    setViewportWidth(window.innerWidth)
    const handleResizeWindow = () => setViewportWidth(window.innerWidth)
    window.addEventListener('resize', handleResizeWindow)
    return () => {
      window.removeEventListener('resize', handleResizeWindow)
    }
  }, [setViewportWidth])

  const getParallaxHeight = (screenWidth: number) => {
    if (screenWidth > 400) {
      return '42rem'
    } else {
      return '45rem'
    }
  }

  return (
    <Layout>
      <>
        {/* HERO */}
        <div>
          <VideoHeroLocal
            copyElement={
              <>
                <p className="text-white subtitle opacity-70">
                  // Quantum Hardware
                </p>
                <h1>Quantum computational advantage</h1>
                <div className="mt-5.5">
                  <div className="flex">
                    <Modal
                      interactionStyles="block"
                      interaction={
                        <div className="watch-video mb-3">
                          <ImageVideoPlayIconSvg
                            circleColor="text-white"
                            widthAndHeight={30}
                          />
                          <span className="swipe-link swipe-link-white ml-2.5 text-lg font-semibold border-b-2 border-white">
                            <span data-hover="Watch Video">Watch Video</span>
                          </span>
                        </div>
                      }
                      title="Quantum Computational Advantage with Borealis"
                      content={
                        <div className="video-wrapper">
                          <iframe
                            src="https://www.youtube.com/embed/bnX57EjvFVQ?autoplay=1"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      }
                    />
                    <Link to="/blog/beating-classical-computers-with-Borealis">
                      <span className="swipe-link swipe-link-white ml-2.5 text-lg font-semibold border-b-2 border-white">
                        <span data-hover="Read Blog">Read Blog</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </>
            }
            video={heroVideo as string}
            aspectRatio={2538 / 1080}
            thumbnail={heroVideoThumbnail as string}
          />
        </div>
        {/* Cloud */}
        <div className="standard-page-section">
          <div className="inner-container">
            <div className="lg:w-5/12">
              <p className="subtitle">// Xanadu Cloud</p>
              <h2>Your quantum playground</h2>
              <p className="main-p">
                Xanadu Cloud provides you with hardware, software and
                applications for quantum computing.&nbsp;{' '}
                <a
                  href="mailto:hardware-access@xanadu.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact us
                </a>{' '}
                to get started.
              </p>
            </div>
            <div className="flex flex-row flex-wrap -mx-3 lg:mt-10">
              {cloudSteps.map((item, i) => {
                return (
                  <div
                    className={`w-full px-3 md:w-1/2 lg:w-1/4 mt-10 lg:mt-0`}
                    key={i}
                  >
                    <div className="w-full h-full max-h-[201px]">
                      <GatsbyImage
                        className="w-full h-full object-cover"
                        image={getImageDataByFilename(item.image)}
                        alt=""
                      />
                    </div>
                    <div className="flex items-center mt-5 lg:mt-8 lg:block">
                      <h3 className="text-2xl">{item.step}</h3>
                    </div>
                    <p className="mt-5">{item.details}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {/* Get Started */}
        <div
          className="relative"
          style={{ height: getParallaxHeight(viewportWidth) }}
        >
          <div className="absolute w-full h-full">
            <GetStartedParallaxSection coordinates={[-0, -80]} />
            <GetStartedParallaxSection coordinates={[-80, 0]} />
            <GetStartedParallaxSection coordinates={[0, -80]} />
          </div>
          <div className="absolute w-full h-full parallax-gradient"></div>
          <div className="absolute w-full overflow-hidden standard-page-section">
            <div className="inner-container">
              <div className="lg:w-6/12">
                <p className="subtitle">// Quantum Software</p>
                <h2>Build with PennyLane</h2>
                <p className="main-p">
                  Develop quantum applications and program quantum computers
                  with PennyLane&apos;s library of tools, demonstrations,
                  tutorials and community support forum.
                </p>
                <Link
                  to="/products/pennylane"
                  className="mt-5.5 btn btn-lg btn-1"
                >
                  Try now
                </Link>
              </div>
              <div className="mt-8.5">
                <Slider
                  width="w-1/4"
                  extraClass="slider-homepage"
                  demos={demosArray}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Partnerships */}
        <div className="overflow-hidden bg-partnerships standard-page-section">
          <div className="inner-container">
            {/* text */}
            <div className="flex flex-col items-center text-center text-white">
              <p className="text-white subtitle opacity-70">// Partnerships</p>
              <h2>Collaborate with Xanadu</h2>
              <p className="main-p md:max-w-lg">
                Partnering with leading companies to break new ground in
                hardware, software and quantum applications.
              </p>
              <br />
              <div className="hidden sm:flex justify-between lg:justify-around w-full md:w-10/12 lg:w-8/12 flex-col md:flex-row h-25 md:h-4 lg:px-8 xl:px-16">
                <div>
                  <a
                    href="https://arxiv.org/abs/2204.11890"
                    target="_blank"
                    className="swipe-link swipe-link-white ml-2.5 text-lg font-semibold border-b-2 border-white"
                    rel="noopener noreferrer"
                  >
                    <span data-hover="Volkswagen: Battery Simulation">
                      Volkswagen: Battery Simulation
                    </span>
                  </a>
                </div>
                <div>
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href="https://pennylane.ai/qml/demos/tutorial_tn_circuits/"
                    target="_blank"
                    className="swipe-link swipe-link-white ml-2.5 text-lg font-semibold border-b-2 border-white"
                    rel="noopener"
                  >
                    <span data-hover="BMW: Quantum Machine Learning">
                      BMW: Quantum Machine Learning
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <Partnerships />
          </div>
        </div>
        {/* Company (Careers) */}
        <div className="py-15 standard-page-section">
          <div className="inner-container">
            <div className="flex flex-col items-center text-center">
              <p className="subtitle">// Join Xanadu</p>
              <h2 className="mt-2.5">Come make a difference with us</h2>
              <p className="mt-5 md:w-128">
                Work with our team of high performing quantum experts and
                builders, leading the path towards fault tolerant quantum
                computers.
              </p>
              <div className="mt-10 -mb-5">
                <a
                  href="https://xanadu.applytojob.com/apply"
                  target="_blank"
                  className="mb-5 mx-2.5 btn btn-lg btn-1"
                  rel="noopener noreferrer"
                >
                  Open Positions
                </a>
                <Link to="/research" className="mx-2.5 btn btn-lg btn-2">
                  Research
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default Homepage
