import * as React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import Homepage from '../components/homepage'
import { CookiesProvider } from 'react-cookie'
import Seo from '../components/Seo'

const IndexPage = () => {
  return (
    <CookiesProvider>
      <ParallaxProvider>
        <Homepage />
      </ParallaxProvider>
    </CookiesProvider>
  )
}

export default IndexPage

export const Head = () => {
  return (
    <Seo
      title="Welcome to Xanadu"
      description="Xanadu is a Canadian quantum computing company with the mission to build quantum computers that are useful and available to people everywhere."
      image="homepage.jpg"
    />
  )
}
