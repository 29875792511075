import * as React from 'react'
import { Parallax } from 'react-scroll-parallax'

import { StaticImage } from 'gatsby-plugin-image'

type GetStartedParallaxSectionProps = {
  coordinates: number[]
}

const GetStartedParallaxSection = ({
  coordinates,
}: GetStartedParallaxSectionProps) => {
  return (
    <Parallax
      x={coordinates}
      className="relative overflow-hidden h-1/3 bg-grey-200"
      styleInner={{ height: '100%' }}
    >
      <div className="flex flex-row items-start h-full opacity-10 flex-nowrap">
        <StaticImage
          src={'../../images/homepage/parallax_tile.png'}
          alt=""
          className="flex-none w-auto h-full max-w-none"
        />
        <StaticImage
          src={'../../images/homepage/parallax_tile.png'}
          alt=""
          className="flex-none w-auto h-full max-w-none"
        />
      </div>
    </Parallax>
  )
}

export default GetStartedParallaxSection
